var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "users-smart-grid" }, [
    _c("div", { staticClass: "users-smart-grid__scrollable" }, [
      _c("div", { ref: "grid", staticClass: "users-smart-grid__grid" }, [
        _c(
          "div",
          { staticClass: "users-smart-grid__grid-cnt", style: _vm.cntStyle },
          [
            _vm._l(_vm.usersWithStyle, function (userWithStyle) {
              return _c(
                "div",
                {
                  key: "grid-" + userWithStyle.user.id,
                  staticClass: "users-smart-grid__sqaure px-2 py-2",
                  style: userWithStyle.style,
                },
                [_vm._t("option", null, { user: userWithStyle.user })],
                2
              )
            }),
            _c("ResizeObserver", { on: { notify: _vm.handleResize } }),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }