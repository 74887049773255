<template>
  <div class="users-smart-grid">
    <div class="users-smart-grid__scrollable">
      <div class="users-smart-grid__grid" ref="grid">
        <div class="users-smart-grid__grid-cnt" :style="cntStyle">
          <div
            v-for="userWithStyle in usersWithStyle"
            :key="`grid-${userWithStyle.user.id}`"
            class="users-smart-grid__sqaure px-2 py-2"
            :style="userWithStyle.style"
          >
            <slot name="option" :user="userWithStyle.user" />
          </div>
          <ResizeObserver @notify="handleResize" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import _ from "lodash"
import { ResizeObserver } from "vue-resize"
import fit from "@shared/helpers/fit"
import User from "@shared/User"
import MeetingUserCard from "@/components/GroupTeams/Common/Player/MeetingUserCard/MeetingUserCard"

const MIN_WIDTH = 160
const MAX_WIDTH = 600

export default Vue.extend({
  name: "UsersSmartGrid",
  components: {
    MeetingUserCard,
    ResizeObserver
  },
  props: {
    maxWidth: {
      type: Number,
      default: MAX_WIDTH,
      required: false
    },
    minWidth: {
      type: Number,
      default: MIN_WIDTH,
      required: false
    },
    users: {
      type: Array,
      required: true
    },
    showPinned: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      width: 0,
      height: 0
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.height = this.$refs.grid?.offsetHeight || 0
      this.width = this.$refs.grid?.offsetWidth || 0
    })
  },
  computed: {
    ...mapGetters("auth", ["isHost"]),
    ...mapGetters(["isDIYGame", "actualGameID", "orgID"]),
    usersWithStyle() {
      const pinedUserID = this.pinedUserID
      const itemWidth = this.itemWidth
      const isHighlightedUser = this.isHighlightedUser
      const isOneUser = this.isOneUser
      const getStyle = user => {
        const isPinned = pinedUserID === user.id
        const height = itemWidth * (isPinned ? 2 : 1)
        const width = !isPinned && isHighlightedUser(user) ? height * 2 : height
        return `
          ${isOneUser ? "margin: auto" : "float: left"};
          width: ${width}px;
          height: ${height}px;
        `
      }

      return this.users.map(user => ({
        user,
        style: getStyle(user)
      }))
    },
    isOneUser() {
      return this.users.length === 1
    },
    itemWidth() {
      const { width, height } = this
      if (width === 0 || height === 0) return 0
      const items = this.users
      const nOfUsers = items.length
      if (!nOfUsers) return 0
      const nOfConferenceUsers = items.filter(this.isHighlightedUser).length
      const cellCount =
        nOfUsers +
        Math.ceil(nOfConferenceUsers * 2) +
        (this.pinedUserID ? 3 : 0)
      const computedWidth = fit(width, height, cellCount) - 2
      return (
        Math.floor(
          Math.max(this.minWidth, Math.min(computedWidth, this.maxWidth))
        ) - 1
      )
    },
    cntStyle() {
      const { width, itemWidth } = this
      const columnCount = Math.floor(width / itemWidth)
      return `width: ${columnCount * itemWidth}px;`
    },
    pinedUserID() {
      return this.showPinned && this.$store.getters?.gameStatus?.pinedUserID
    }
  },
  methods: {
    isHighlightedUser(user) {
      return User.isConference(user)
    },
    handleResize() {
      this.height = 0
      this.width = 0
      this.$nextTick(() => {
        this.height = this.$refs.grid?.offsetHeight || 0
        this.width = this.$refs.grid?.offsetWidth || 0
      })
    }
  }
})
</script>

<style lang="scss">
.users-smart-grid {
  position: relative;
  display: flex;
  flex: 1;

  &__scrollable {
    overflow-y: auto;
    align-items: flex-start;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__grid {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    min-width: 100%;
  }

  &__grid-cnt {
    overflow: hidden;
    pointer-events: auto;
  }

  &__sqaure {
    text-align: center;
  }
}
</style>
